<template>
    <div class="next-collapse-component">
        <div class="header-content">
            <div class="left">
                <template v-if="title">
                    <span class="title">{{title}}</span>
                </template>
                <template v-else>
                    <solt name="title">{{ $t('component.NextCollapse_title') }}</solt>
                </template>
            </div>
            <div :class="['right', isShow?'show':'']" @click="isShow=!isShow">
                <svg-icon name="ib-d-arrow-down"></svg-icon>
            </div>
        </div>
        <template name="collapse-content-fade" enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
            <div class="collapse-content" v-show="isShow">
                <slot></slot>
            </div>
        </template>
    </div>
    
</template>
<script>
export default {
    props:{
        title:{
            type: String,
            default: '',
        },
        show: {
            type:Boolean,
            default: true
        }
    },
    data(){
        return {
            isShow:true,
        }
    },
    created(){
        this.isShow = this.show;
    }
}
</script>
<style lang="scss" scoped>
.next-collapse-component{
    background-color: #ffffff;
    .header-content{
        display: flex;
        height: 50px;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #0000001A;
        box-sizing: border-box;
        border-radius: 6px 6px 0 0;
        padding: 0 20px;
        > left{
            > .title{
                font-size: 15px;
            }
        }
        > .right{
            height: 100%;
            font-size: 18px;
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
                transition:transform .2s linear
            }
            &.show{
                svg {
                    transform: rotateZ(180deg);
                }
            }
        }
    }
    .collapse-content{
        padding: 20px;
        border: 1px solid #0000001A;
        border-top: none;
        box-sizing: border-box;
        border-radius: 0 0 6px 6px;
    }
}
</style>
