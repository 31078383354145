<template>
    <div class="upgrade-pro-component">
        <div class="default-wapper">
            <div class="default-main">
                <vip-free size="42"></vip-free>
                <div class="margin-b-15 fonts-20 text-weight-700">{{userInfo.accountTypeDesc}}</div>
                <div class="margin-b-10" v-if="userInfo.accountType === 'personal'">{{ $t('component.UpgradePro_message_1') }}</div>
                <div class="margin-b-10 color-error" v-if="userInfo.accountType === 'pro-limited'">{{ $t('component.UpgradePro_pro_limited_label') }}</div>
                <div class="margin-b-30 fonts-13" style="line-height: 18px;">{{ $t('component.UpgradePro_message_2') }}</div>
                <el-button type="primary" round size="medium" @click="upgradeProBtnEvent">{{ $t('component.UpgradePro_button_upgrade') }}</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import VipFree from '@/components/icon/VipFree';
export default {
    name: "UpgradePro",
    components: { VipFree },
    computed:{
        ...mapState({
			userInfo: state => state.user.userData,
		}),
    },
    methods: {
        upgradeProBtnEvent(){
            this.$router.push('/account/vip');
            // this.$store.dispatch('pay/setPayDialogAction',{show:true});
        }
    }
}
</script>
<style lang="scss" scoped>
.upgrade-pro-component{
    display: flex;
    justify-content: center;
    .default-wapper{
        width: 460px;
        height: 460px;
        border-radius: 50%;
        background: #F2F2F2;
        display: flex;
        align-items: center;
        justify-content: center;
        .default-main{
            width: 300px;
            text-align: center;
            
            
        }
    }
    
}
</style>
